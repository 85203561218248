.main-wrapper {
  @include display-flex;
  overflow: hidden;
  .page-wrapper {
    min-height: 100vh;
    background: $content-bg;
    width: calc(100% - #{$sidebar-width-lg});
    margin-left: $sidebar-width-lg;
    @include display-flex;
    @include flex-direction(column);
    -webkit-transition: margin .1s ease, width .1s ease;
    transition: margin .1s ease, width .1s ease;
    .page-content {
      flex-grow: 1;
      padding: 25px;
      margin-top: $navbar-height;
      @media(max-width: 767px) {
        padding: 25px 15px;
      }
    }
    &.full-page {
      width: 100%;
      margin-left: 0;
      .page-content {
        margin-top: 0;
      }
    }
    @media(max-width: 991px) {
      margin-left: 0;
      width: 100%;
    }
  }
}

.page-content {
  .main-content {
  }
  .content-nav-wrapper {
    padding: 0;
    position: fixed;
    top: calc(#{$navbar-height} + 30px);
    right: 0;
    width: $content-nav-width;
    max-height: calc(100% - #{$navbar-height} - 51px);
    border-left: 1px solid $border-color;
    display: none;
    @media(min-width: 1200px) {
      display: block;
    }
    .content-nav {
      padding: 0px 25px;
      .nav-item {
        .nav-link {
          padding: 0;
          height: 30px;
          white-space: nowrap;
          color: #383838;
          font-size: $default-font-size;
          @include display-flex;
          @include align-items(center);
        }
      }
    }
  }
}